// config.js


const config = {
  projectId: 'matchup-9ebf8',
  domain: 'https://matchers.tangoai.co',
  
  sidebarPages: {
    admin: ['Dashboard', 'Reports', 'Playground', 'Optimization'],
    user: ['Dashboard', 'Playground']
  },
  
  exemptedColumns: [
  ]
};

export default config;